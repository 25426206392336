import fetch from "auth/FetchInterceptor";

const SampleInProcess = {};

SampleInProcess.get = function (payload) {
  return fetch({
    url: `/reports/sample-in-process?
    startdate=${payload.startDate}&
    enddate=${payload.endDate}&
    agency=${payload.agency}&
    department=${payload.department}`,
    method: "get",
  });
};


export default SampleInProcess;
