import fetch from "auth/FetchInterceptor";

const RedrawSamples = {};

RedrawSamples.getRedrawSamples = function (payload) {
    return fetch({
        url: `/reports/redraw-samples?startdate=
            ${payload.startDate}&enddate=
            ${payload.endDate}&agency=
            ${payload.agency}`,
        method: "get",
    });
};

export default RedrawSamples;
