import { FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST, FETCH_SAMPLE_COLLECTED_ORDERS_SUCCESS, FETCH_SAMPLE_COLLECTED_ORDERS_FAILURE } from "constants/OrderStatus/SampleCollectedOrders"

export const fetchSampleCollectedOrdersRequest = (startDate, endDate, agency) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_REQUEST,
        payload: {
            startDate,
            endDate,
            agency
        }
    }
}

export const fetchSampleCollectedOrdersSuccess = (payload) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_SUCCESS,
        payload: payload
    }
}

export const fetchSampleCollectedOrdersFailure = (payload) => {
    return {
        type: FETCH_SAMPLE_COLLECTED_ORDERS_FAILURE,
        payload: payload
    }
}