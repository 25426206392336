import {
    FETCH_ALL_ORDER_REQUEST,
    FETCH_ALL_ORDER_SUCCESS,
    FETCH_ALL_ORDER_FAILURE,
    FETCH_ORDER_OBX_REQUEST,
    FETCH_ORDER_OBX_SUCCESS,
    FETCH_ORDER_OBX_FAILURE,
    UPDATE_ALL_ORDER_REQUEST,
    UPDATE_ALL_ORDER_FAILURE,
    UPDATE_ALL_ORDER_SUCCESS,
    SHOW_ALL_ORDER_OBX_DIALOG,
    FETCH_COMMUNICATED_CONTENT_FAILURE,
    FETCH_COMMUNICATED_CONTENT_REQUEST,
    FETCH_COMMUNICATED_CONTENT_SUCCESS,
    SAVE_COMMUNICATED_CONTENT_FAILURE,
    SAVE_COMMUNICATED_CONTENT_REQUEST,
    SAVE_COMMUNICATED_CONTENT_SUCCESS,
    DELETE_ORDER_ALL_PATIENT_SUCCESS,
    DELETE_ORDER_ALL_PATIENT_REQUEST,
    UPDATE_PATIENT_DEMOGRAPHY_SUCCESS
} from "constants/OrderStatus/all-orders"

export const fetchAllOrderRequest = (startDate, endDate, agency, department) => {
    return {
        type: FETCH_ALL_ORDER_REQUEST,
        payload: {
            startDate,
            endDate,
            agency,
            department
        }
    }
}

export const fetchAllOrderSuccess = (payload) => {
    return {
        type: FETCH_ALL_ORDER_SUCCESS,
        payload: payload
    }
}

export const fetchAllOrderFailure = (payload) => {
    return {
        type: FETCH_ALL_ORDER_FAILURE,
        payload: payload
    }
}

export const fetchOrderObxRequest = (visitUuid) => {
    return {
        type: FETCH_ORDER_OBX_REQUEST,
        payload: {
            visitUuid
        }
    }
}

export const fetchOrderObxSuccess = (payload) => {
    return {
        type: FETCH_ORDER_OBX_SUCCESS,
        payload: payload
    }
}

export const fetchOrderObxFailure = (payload) => {
    return {
        type: FETCH_ORDER_OBX_FAILURE,
        payload: payload
    }
}

export const updateAllOrdersRequest = (payload) => {
    return {
        type: UPDATE_ALL_ORDER_REQUEST,
        payload: payload
    }
}

export const updateAllOrdersSuccess = (payload) => {
    return {
        type: UPDATE_ALL_ORDER_SUCCESS,
        payload: payload
    }
}

export const updateAllOrdersFailure = (payload) => {
    return {
        type: UPDATE_ALL_ORDER_FAILURE,
        payload: payload
    }
}

export const updatePatientDemographySuccess = (payload) => {
    return {
        type: UPDATE_PATIENT_DEMOGRAPHY_SUCCESS,
        payload: payload
    }
}

export const deleteOrderAllPatientRequest = (payload) => {
    return {
        type: DELETE_ORDER_ALL_PATIENT_REQUEST,
        payload: payload
    }
}

export const deleteOrderAllPatientSuccess = (payload) => {
    return {
        type: DELETE_ORDER_ALL_PATIENT_SUCCESS,
        payload: payload
    }
}


export const deleteOrderAllPatientFailure = (payload) => {
    return {
        type: DELETE_ORDER_ALL_PATIENT_SUCCESS,
        payload: payload
    }
}


export const showAllOrderObxDialog = (payload) => {
    return {
        type: SHOW_ALL_ORDER_OBX_DIALOG,
        payload: payload
    }
}


export const fetchCoummunicatedContentsRequest = (payload) => {
    return {
        type: FETCH_COMMUNICATED_CONTENT_REQUEST,
        payload: payload
    }
}

export const fetchCoummunicatedContentsSuccess = (payload) => {
    return {
        type: FETCH_COMMUNICATED_CONTENT_SUCCESS,
        payload: payload
    }
}

export const fetchCoummunicatedContentsFailure = (payload) => {
    return {
        type: FETCH_COMMUNICATED_CONTENT_FAILURE,
        payload: payload
    }
}


export const saveCommunicatedContentRequest = (payload) => {
    return {
        type: SAVE_COMMUNICATED_CONTENT_REQUEST,
        payload: payload
    }
}

export const saveCommunicatedContentSuccess = (payload) => {
    return {
        type: SAVE_COMMUNICATED_CONTENT_SUCCESS,
        payload: payload
    }
}

export const saveCommunicatedContentFailure = (payload) => {
    return {
        type: SAVE_COMMUNICATED_CONTENT_FAILURE,
        payload: payload
    }
}

