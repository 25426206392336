import { doesThisUserIsIclUser } from "utils/CurrentUserUtil";

const dev = {

  API_ENDPOINT_URL: "http://localhost:8000/api",
  API_BASE_URL: "http://localhost:8000/api",
  PDF_API_BASE_URL: "http://localhost:8000/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "http://localhost:8000/api",
  API_END_POINT_FOR_PDF : "http://localhost:8000/",
  API_END_POINT_FOR_IMG : "http://localhost:8000/"

  // API_ENDPOINT_URL: "http://192.168.2.68:8000/api",
  // API_BASE_URL: "http://192.168.2.68:8000/api",
  // PDF_API_BASE_URL: "http://192.168.2.68:8000/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "http://192.168.2.68:8000/api",
  // API_END_POINT_FOR_PDF : "http://192.168.2.68:8000/",
  // API_END_POINT_FOR_IMG : "http://192.168.2.68:8000/"

  // API_ENDPOINT_URL: "https://drsportal.iclpartner.com/api",
  // API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "https://drsportal.iclpartner.com/",
  // API_END_POINT_FOR_IMG : "https://drsportal.iclpartner.com/",

  // API_ENDPOINT_URL : "https://backendtest.iclpartner.com/api",
  // API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "http://localhost:8000/",
};

const prod = {
  // API_ENDPOINT_URL : "https://backendtest.iclpartner.com/api",
  // API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  // API_END_POINT_FOR_PDF : "https://backendtest.iclpartner.com/",
  
  API_ENDPOINT_URL: "https://drsportal.iclpartner.com/api",
  API_BASE_URL: "https://drsportal.iclpartner.com/api",
  PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "https://drsportal.iclpartner.com/api",
  API_END_POINT_FOR_PDF : "https://drsportal.iclpartner.com/",
  API_END_POINT_FOR_IMG : "https://drsportal.iclpartner.com/",
};

const test = {
  API_ENDPOINT_URL : "https://backendtest.iclpartner.com/api",
  API_BASE_URL: "https://backendtest.iclpartner.com/api",
  PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  MICRO_BIOLOGY_PDF_API_BASE_URL: "https://backendtest.iclpartner.com/api",
  API_END_POINT_FOR_PDF : "https://backendtest.iclpartner.com/",
  API_END_POINT_FOR_IMG : "https://drsportal.iclpartner.com/",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const APP_NAME = "ICL";
export const IMG_PATH = "/img/ICL/";
export const ONLY_FOR_CURRENT_COMPANY_LABEL = "Is ICL uSER";
export  const LOGIS_BASE_URL = "https://cerbalancet-africa.legasi.net/external/icl";
export const ALLOWED_FOR_AGENCIES = false;
export const EXTRA_FEE_FOR_STAT_TESTS = 495;
export const EXTRA_FEE_FOR_QUEUE_PRIORITY = 740;
export const CONFIG_LABEL = doesThisUserIsIclUser() ? "Config" : "Test Menu";
export const TV_SCREEN_LABEL = "Tv screen results";
export const SCREENS_LABEL = "SCREENS";
export const PAYMENT_LABEL = doesThisUserIsIclUser() ? "Income" : "Payment";
export const LOGIN_LOGO = "/login-logo.png";
export const env = getEnv();
