import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_REDRAW_SAMPLES_REQUEST } from "constants/OrderStatus/RedrawSamples";
import { fetchRedrawSamplesFailure, fetchRedrawSamplesSuccess } from "redux/actions";
import RedrawSamples from "services/OrderStatus/RedrawSamples";

export function* onFetchRedrawSamples() {
    yield takeLatest(FETCH_REDRAW_SAMPLES_REQUEST, function* ({ payload }) {
        try {
            const result = yield call(RedrawSamples.getRedrawSamples, payload);
            if (result.status) {
                yield put(fetchRedrawSamplesSuccess(result.data));
            } else {
                yield put(fetchRedrawSamplesFailure(result.message));
            }
        } catch (error) {
            yield put(fetchRedrawSamplesFailure(error.message));
        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(onFetchRedrawSamples)
    ]);
}
