import {
    FETCH_PENDINGRESULTS_OBX_REQUEST,
    FETCH_PENDINGRESULTS_OBX_SUCCESS,
    FETCH_PENDINGRESULTS_OBX_FAILURE
} from "constants/PendingResultObx"

const initState = {
    list: [],
    loading: false,
    errorMessage: ""
}

const PendingResultsObx = (state = initState, action) => {
    switch (action.type) {
        case FETCH_PENDINGRESULTS_OBX_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_PENDINGRESULTS_OBX_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.payload
                //list : changeDateToHumanReadableFormat(action.payload)
            }
        case FETCH_PENDINGRESULTS_OBX_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
}

export default PendingResultsObx;