// import React, { useState } from 'react';
// import { WhatsAppOutlined } from '@ant-design/icons';
import { Grid, Layout } from "antd";
import { db } from "auth/FirebaseAuth";
import Footer from 'components/layout-components/Footer';
import HeaderNav from 'components/layout-components/HeaderNav';
import MobileNav from 'components/layout-components/MobileNav';
import PageHeader from 'components/layout-components/PageHeader';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import { ShowNotification } from "components/shared-components/NotificationApi/ShowNotification";
import { NOTIFICATION_KEY } from "configs/AppConfig";
import navigationConfig from "configs/NavigationConfig";
import {
  DIR_LTR,
  DIR_RTL,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH
} from 'constants/ThemeConstant';
import React, { useEffect, useRef, useState } from 'react';
import ReactConfetti from "react-confetti";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { connect } from 'react-redux';
import { fetchAllNotificationsSuccess } from "redux/actions";
import utils from 'utils';
import { doesThisUserIsIclUser } from "utils/CurrentUserUtil";
import AdminViews from 'views/admin-views';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AdminLayout = ({ 
  navCollapsed, 
  navType, 
  location = [], 
  direction, 
  fetchNotifications,
  shouldShowAndAlertNotification,currentTheme }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const chatCollection = db.collection('chats_for_client_portal');
  const isMobile = !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();
  // const [isChatVisible, setIsChatVisible] = useState(false);
  // const [chatHeight, setChatHeight] = useState("0%");
  const chatRef = useRef(null);
  // const toggleChatVisibility = () => {
  //   setIsChatVisible(!isChatVisible);
  //   setChatHeight("55%")
  // };
  const handleOutsideClick = (e) => {
    if (chatRef.current && !chatRef.current.contains(e.target)) {
      // setIsChatVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const playNotificationSound = () => {
    const audio = new Audio('/audio/notification_sound.wav'); 
    audio.volume = 0.05;
    audio.play(); 
  };

  useEffect(() => {
    if(doesThisUserIsIclUser()){
      const unsubscribe =  chatCollection
      .onSnapshot(snapshot => {   
        // const newChatData = snapshot.docs.map((doc) => ({
        //   id: doc.id,
        //   ...doc.data(),
        // }));
      // Detect 'added' documents
      // const newDocuments = snapshot.docChanges().filter((change) => change.type === 'added' || change.type === 'modified');
      const newDocuments = snapshot.docChanges().filter((change) => change.type === 'added');

      if(newDocuments.length > 0 ){
        
        // console.log("total notifications are " + newDocuments.length);
        if(localStorage.getItem(NOTIFICATION_KEY) === "ALERT"){
          ShowNotification('info', 'bottomRight', `You have ${newDocuments.length} notifications on orders`);
          playNotificationSound();
        }
        // fetchNotifications(newChatData);
      }
      });
    // Clean up the listener on component unmount
    return () => unsubscribe();
    }

  }, [fetchNotifications, chatCollection]);


  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      {
        (window.location.href === 'http://localhost:3000/admin/core_lab'
          || window.location.href === 'http://localhost:3000/admin/pathology'
          || window.location.href === 'http://localhost:3000/admin/tb_micro'
          || window.location.href === 'http://localhost:3000/admin/reset/'
          || window.location.href === 'http://192.168.0.26:3000/admin/reset/'
          || window.location.href === 'https://drportal.iclpartner.com/admin/reset/'

          || window.location.href === 'https://test.iclpartner.com/admin/core_lab'
          || window.location.href === 'https://test.iclpartner.com/admin/pathology'
          || window.location.href === 'https://test.iclpartner.com/admin/tb_micro'
          || window.location.href === 'https://test.iclpartner.com/admin/reset/'
          ) 
          ? 
          <AdminViews /> :
          <>
            <HeaderNav isMobile={isMobile} />
            {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null}
            <Layout style={{maringLeft:"20px"}} className="app-container">
              {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} /> : null}
              <Layout className="app-layout" style={getLayoutDirectionGutter()}>
                <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
                  <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                  <Content>
                    <AdminViews />
                    {/* <Affix style={{ position: 'fixed', bottom: 50, right: 50 }}>
                      <div style={{ textAlign: 'center' }}>
                        <Button type="primary" shape="circle" icon={<WhatsAppOutlined />} onClick={showModal} />
                        <div>Chat with Us</div>
                      </div>
                    </Affix> */}
                    
                    {/* <Affix style={{ position: 'fixed', bottom: 50, right: 50 }}>
                      <div style={{ textAlign: 'center' }}>
                        <Button type="primary" shape="circle" icon={<WhatsAppOutlined />} onClick={toggleChatVisibility} />
                        <div>Chat with Us</div>
                      </div>
                    </Affix> */}
                    {/* {isChatVisible &&
                    <div ref={chatRef}  style={{ backgroundColor : 'transparent'}}>
                      <div style={{
                        position: 'fixed',
                        bottom: 50,
                        right: 50,
                        backgroundColor: 'white',
                        width: '30%',
                        height: chatHeight,
                        border: '1px solid #ccc',
                        borderRadius: 8,
                        padding: 16,
                        zIndex: 9999,
                        transitionDelay: '1s',
                      }}>
                        <h3 style={{ textAlign: 'center' }}>Chat Page</h3>

                        <p >Coming Soon <span style={{fontSize:20}}>😍</span></p>
                      </div>
                      </div>
                    } */}


                  </Content>
                </div>
                <Footer />
              </Layout>
            </Layout>
            {isMobile && <MobileNav />}
          </>
      }
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { navCollapsed, navType, locale, currentTheme } = state.theme;
  const {shouldShowAndAlertNotification} = state.notifications;
  return { navCollapsed, navType, locale,currentTheme, shouldShowAndAlertNotification }
};

const mapDispatchToProps = (dispatch) =>({
  fetchNotifications : (notifications) => dispatch(fetchAllNotificationsSuccess(notifications))
})

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AdminLayout));
