import fetch from "auth/FetchInterceptor";
const searchDoctorsService = {};

searchDoctorsService.search = function (query) {
    return fetch({
        url: `/searchuser`,
        method: 'post',
        data: {contact: query},
        });
  };

export default searchDoctorsService;