import moment from "moment";
import {
  DATE_AND_TIME_FOR_TABLE_FORMAT, MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT,
} from "../constants/DateTimeUtilConstants";
import { Tag } from "antd";
export const changeDateFormatAndReturnNewDate = (oldDate, newFormat) => {
  const newDate = moment(oldDate, "YYYYMMDDhhmmss").format(newFormat);
  //const newDate = moment(oldDate).format(newFormat);
  return newDate;
};

export const initializeTheMomentStartOfWeek = () => {
  moment.updateLocale('en', {
    week: {
      dow: 1, // Monday is the first day of the week.
    }
  });
}

export const changeDateToHumanReadableFormat = (list) => {
  const listContainingHumanReadableDate = list.map((singleJson) => {
    const timeRecieved = singleJson.obrDateTimeSpecimenReceived;
    const timeReleased = singleJson.obrDateTimeResultReleasedLatest;
    const timeCollected = singleJson.obrDateTimeSpecimenCollected;

    const formatedTimeRecieved = timeRecieved.slice(0, 8) + "T" + timeRecieved.slice(8, timeRecieved.length);

    const formatedTimeReleased =
      timeReleased.slice(0, 8) +
      "T" +
      timeReleased.slice(8, timeReleased.length);

    const formatedTimeCollected =
      timeCollected.slice(0, 8) +
      "T" +
      timeCollected.slice(8, timeCollected.length);

    singleJson.obrDateTimeSpecimenReceived = changeDateFormatAndReturnNewDate(
      formatedTimeRecieved,
      DATE_AND_TIME_FOR_TABLE_FORMAT
    );
    singleJson.obrDateTimeResultReleasedLatest =
      changeDateFormatAndReturnNewDate(
        formatedTimeReleased,
        DATE_AND_TIME_FOR_TABLE_FORMAT
      );
    singleJson.obrDateTimeSpecimenCollected = changeDateFormatAndReturnNewDate(
      formatedTimeCollected,
      DATE_AND_TIME_FOR_TABLE_FORMAT
    );
    return singleJson;
  });
  return listContainingHumanReadableDate;
};

export const changeFormatedDatetoHumanReadableFormat = (list) => {
  const listContainingHumanReadableDate = list.map(singleJson => {
    singleJson.webAgeOrDateOfBirth = changeDateFormatAndReturnNewDate(singleJson.webAgeOrDateOfBirth, MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT);
    return singleJson;
  });
  return listContainingHumanReadableDate;
}

export function calculateAge(date, pageType = "order") {
  let dateString = pageType === "result" ? date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6,8) : date;
  // Parse the input date string
  var birthDate = new Date(dateString);
  // Get the current date
  var currentDate = new Date();

  // Calculate the difference in years and months
  var years = currentDate.getFullYear() - birthDate.getFullYear();
  var months = currentDate.getMonth() - birthDate.getMonth();

  // Adjust for negative months
  if (months < 0) {
    years--;
    months += 12;
  }

  // Display the age in the desired format
  if (years > 0) {
    return <Tag color="blue">{years}Y</Tag>;
  } else {
    return <Tag color="blue">{months}M</Tag>;
  }
}


