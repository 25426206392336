import fetch from "auth/FetchInterceptor";

const DashboardReport = {};

DashboardReport.getDashboard = function (payload) {
  return fetch({
    url: `/getdashboard?startdate=${payload.startdate}&enddate=${payload.enddate}&agency=${payload.agency}`,
    method: "get",
  });
};

DashboardReport.getCashierDashboard = function (payload) {
  return fetch({
    url: `/cashier-dashboard?startdate=
    ${payload.startdate}&enddate=
    ${payload.enddate}&agency=
    ${payload.agency}&dateDifference=
    ${payload.dateDifference}`,
    method: "get",
  });
};

export default DashboardReport;
