import React, {useEffect, useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List } from 'antd';
import { BellOutlined, UserOutlined} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { connect } from 'react-redux';
import { fetchAllNotificationsRequest, updateNotificationRequest } from 'redux/actions';
import { doesThisUserIsDoctor } from 'utils/CurrentUserUtil';
import { useHistory } from 'react-router-dom';
import { db } from 'auth/FirebaseAuth';
import { NewTest } from 'views/doctors-app-views/newTest/newTest';

const getNotificationBody = (list, history, notification, setClicked, clicked) => {
  const DrNotification = list.filter(notification => notification.doctor);
  const iclLogo = 'https://media.licdn.com/dms/image/C4D03AQGqgwyPU-xLvg/profile-displayphoto-shrink_200_200/0/1621602275271?e=2147483647&v=beta&t=_aDRAoSLx50AL5NOAHMh8X_CJthjz3ijXJnCFg1jdYA'
  return list.length > 0 ? 
    <List
    size="small"
    itemLayout="horizontal"
    dataSource={doesThisUserIsDoctor() ? DrNotification : list}
    renderItem={appointment => (
      notification?.map((notificationItem, index) => (
        <List.Item key={index} className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              <Avatar src={iclLogo || ''} className={`ant-avatar-success`} icon={<UserOutlined />} />
            </div>
            {doesThisUserIsDoctor() ? (
              <div 
                //className="mr-3" 
                //onClick={() => openModal(notificationItem)}
                style={{cursor: 'pointer', display: 'flex',flexDirection: 'row', alignItems: 'center'}}
              >
                <p>A new test has just been released. Check it out!</p> 
                
                <NewTest 
                  history={history} 
                  setClicked={setClicked} 
                  // style={{width: 'fit-content'}} 
                  bellClicked={true} 
                  testDetail={notificationItem} 
                />
                
              </div>
            ) : (
              <div className="mr-3">
                <span className="font-weight-bold text-dark">{appointment.fullName}</span>
                <span className="text-gray-light">Coming soon 🔜 </span>
              </div>
            )}
            {/* <small className="ml-auto">''</small> */}
          </Flex>
        </List.Item>
      ))  
    )}
    /> : 
    <div className="empty-notification">
      <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
      <p className="mt-3">You have viewed all notifications</p>
    </div>;
  }

const NavNotification = ({ cart, language, fetchNotifications, updateNotifications, currentUser}) => {
  const history = useHistory();
  const [notification, setNotification] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [visible, setVisible] = useState(false);
   
  useEffect(() => {
    const getNewBellTestcreatedNotifications = async () => {
      const snapshot = await db.collection('newly created tests').orderBy('created_at', 'desc').limit(4).get();
      const newNotification = snapshot.docs
        .map(doc => doc.data())
        .reduce((acc, current) => {
          const x = acc.find(item => item.testId === current.testId);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
      setNotification(newNotification);
    };
  
    fetchNotifications();
    getNewBellTestcreatedNotifications();
    
  }, [fetchNotifications, clicked, currentUser?.id]);


  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }


  const clearNotification = () =>{
   //now it is time to update the notification cart
   updateNotifications();
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{language.notification}</h4>
          <h5 onClick={clearNotification} style={{cursor : 'pointer'}} className="ml-auto">{language.clear}</h5>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(cart, history, notification, setClicked, clicked)}
      </div>
    </div>
  );

  // what happens when the user clicks the bell notification
  const setNotificationAsSeen = async (notification) => {
    setClicked(true);
    if (
      notification &&
      notification.length > 0 &&
      notification[0].testId &&
      currentUser &&
      currentUser.id
    ) {
      const docRef = db.collection('newly created tests').where('testId', '==', notification[0].testId);
      try {
        const snapshot = await docRef.get();

        // if the document is found, update the usersShownNotification array
        if (!snapshot.empty) {
          snapshot.forEach(async (doc) => {
            const docData = doc.data()
            let notifiedUsersDB = docData.usersShownNotification || [] // List of notified users
            
            if(!notifiedUsersDB.includes(currentUser.id)){
              notifiedUsersDB.push(currentUser.id)
              await doc.ref.update({
                usersShownNotification: notifiedUsersDB
              });
            }
            console.log("User ID already added to array!");
    
          });
        } else {
          console.error("No document found with the given testId:", notification[0].testId);
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    } else {
      console.error("Notification or user data is missing");
    }
  };
  

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={clicked === true ? visible : false}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
            <div onClick={() => doesThisUserIsDoctor() ? setNotificationAsSeen(notification) : setClicked(true)}>
              <Badge count={doesThisUserIsDoctor() && clicked === false && notification && notification.length > 0 && notification[0].usersShownNotification?.includes(currentUser.id) === false ? notification.length  : ''}>
                <BellOutlined className="bellNotification" type="bell" />
              </Badge>
            </div>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps =(state) => {
  //console.log(state)
  return {
  cart: state.notifications.cart,
  language : state.theme.language,
  currentUser: state.auth.currentUser
}}

export const mapDispatchToProps = (dispatch) =>({
  fetchNotifications : () => dispatch(fetchAllNotificationsRequest()),
  updateNotifications : () => dispatch(updateNotificationRequest())
})
export default connect(mapStateToProps, mapDispatchToProps)(NavNotification);
// export default NavNotification;
