import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_STAT_RESULTS_REQUEST } from "constants/StatResults";
import { fetchStatResultsFailure, fetchStatResultsSuccess } from "redux/actions";
import StatResults from "services/StatResults";

export function* onFetchStatResults() {
    yield takeLatest(FETCH_STAT_RESULTS_REQUEST, function* (payload) {
        try {
            const result = yield call(StatResults.getStatResults, payload.payload);
            if (result.status) {
                yield put(fetchStatResultsSuccess(result.data));
            } else {
                yield put(fetchStatResultsFailure(result.message));
            }
        } catch (error) {
            yield put(fetchStatResultsFailure(error.message));
        }
    });
}

export default function* rootSaga() {
    yield all([fork(onFetchStatResults)])
}
