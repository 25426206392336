import { FETCH_AGENCY_DOCTORS_REQUEST } from "constants/FetchAgencyDoctors";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { fetchAgencyDoctorsFailure, fetchAgencyDoctorsSuccess } from "redux/actions";
import fetchAgencyDoctorsService from "services/FetchAgencyDoctors";

export function* fetchAgencyDoctorsSaga() {
  yield takeLatest(FETCH_AGENCY_DOCTORS_REQUEST, function* () {
    try {
      const result = yield call(fetchAgencyDoctorsService.get);
      if (result) {
        yield put(fetchAgencyDoctorsSuccess(result.data));
      } else {
        yield put(fetchAgencyDoctorsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAgencyDoctorsFailure(error.message));
    }
  });
}

export default function* rootSaga(){
  yield all([
    fork(fetchAgencyDoctorsSaga),
  ]);
}