import fetch from "auth/FetchInterceptor";
const addDoctorsByClientCompanyService = {};

addDoctorsByClientCompanyService.addDoctors = function (doctorId, agencyId) {
    return fetch({
        url: `/docotor-association`,
        method: 'post',
        data: {doctor_id: doctorId, agency_id: agencyId},
        });
  };

export default addDoctorsByClientCompanyService;