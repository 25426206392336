import {
    FETCH_APPROVED_ORDER_FAILURE,
    FETCH_APPROVED_ORDER_REQUEST,
    FETCH_APPROVED_ORDER_SUCCESS,
    FETCH_APPROVED_ORDER_OBX_REQUEST,
    FETCH_APPROVED_ORDER_OBX_SUCCESS,
    FETCH_APPROVED_ORDER_OBX_FAILURE,
    UPDATE_APPROVED_ORDER_REQUEST,
    UPDATE_APPROVED_ORDER_FAILURE,
    UPDATE_APPROVED_ORDER_SUCCESS,
    SHOW_APPROVED_ORDER_OBX_DIALOG
} from "constants/OrderStatus/ApprovedOrders"

export const fetchApprovedOrderRequest = (startDate, endDate, agency, department) => {
    return {
        type: FETCH_APPROVED_ORDER_REQUEST,
        payload: {
            startDate,
            endDate,
            agency,
            department
        }
    }
}

export const fetchApprovedOrderSuccess = (payload) => {
    return {
        type: FETCH_APPROVED_ORDER_SUCCESS,
        payload: payload
    }
}

export const fetchApprovedOrderFailure = (payload) => {
    return {
        type: FETCH_APPROVED_ORDER_FAILURE,
        payload: payload
    }
}

export const fetchApprovedOrderObxRequest = (visitUuid) => {
    return {
        type: FETCH_APPROVED_ORDER_OBX_REQUEST,
        payload: {
            visitUuid
        }
    }
}

export const fetchApprovedOrderObxSuccess = (payload) => {
    return {
        type: FETCH_APPROVED_ORDER_OBX_SUCCESS,
        payload: payload
    }
}

export const fetchApprovedOrderObxFailure = (payload) => {
    return {
        type: FETCH_APPROVED_ORDER_OBX_FAILURE,
        payload: payload
    }
}

export const updateApprovedOrdersRequest = (payload) => {
    return {
        type: UPDATE_APPROVED_ORDER_REQUEST,
        payload: payload
    }
}

export const updateApprovedOrdersSuccess = (payload) => {
    return {
        type: UPDATE_APPROVED_ORDER_SUCCESS,
        payload: payload
    }
}

export const updateApprovedOrdersFailure = (payload) => {
    return {
        type: UPDATE_APPROVED_ORDER_FAILURE,
        payload: payload
    }
}

export const showApprovedOrderObxDialog = (payload) => {
    return {
        type: SHOW_APPROVED_ORDER_OBX_DIALOG,
        payload: payload
    }
}