// redux/sagas/searchDoctorsSaga.js
import { SEARCH_DOCTORS_REQUEST } from 'constants/SearchDoctors';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';
import { searchDoctorsFailure, searchDoctorsSuccess } from 'redux/actions';

import searchDoctorsService from 'services/SearchDoctors';


function* searchDoctorsSaga(action) {
    try {
      const result = yield call(searchDoctorsService.search, action.payload);
      if (result.status) {
        yield put(searchDoctorsSuccess(result.data));
      } else {
        yield put(searchDoctorsFailure(result.message));
      }
    } catch (error) {
      yield put(searchDoctorsFailure(error.message));
    }
  }
  
function* watchSearchDoctorsSaga() {
    yield takeLatest(SEARCH_DOCTORS_REQUEST, searchDoctorsSaga);
}
  
export default function* rootSaga() {
yield all([
    fork(watchSearchDoctorsSaga),
]);
}