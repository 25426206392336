import { ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST } from "constants/addDoctorsByClinetCompany";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { addDoctorsByClientCompanyFailure, addDoctorsByClientCompanySuccess } from "redux/actions/addDoctorsByClinetCompany";
import addDoctorsByClientCompanyService from "services/addDoctorsByClinetCompany";


function* addDoctorsByClientCompanySaga(action) {
    try {
      const { doctorId, agencyId } = action.payload;
      console.log('-->>', doctorId, 'agency id ',agencyId);
      const response = yield call(addDoctorsByClientCompanyService.addDoctors, doctorId, agencyId);
      yield put(addDoctorsByClientCompanySuccess(response));
    } catch (error) {
      yield put(addDoctorsByClientCompanyFailure(error.message));
    }
  }
  
  export function* watchAddDoctorsByClientCompany() {
    yield takeLatest(ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST, addDoctorsByClientCompanySaga);
  }

  export default function* rootSaga(){
    yield all([
      fork(watchAddDoctorsByClientCompany)
    ]);
  }