import { SEARCH_DOCTORS_FAILURE, SEARCH_DOCTORS_REQUEST, SEARCH_DOCTORS_SUCCESS } from "constants/SearchDoctors";

const initialState = {
    doctors: [],
    loading: false,
    error: null
  };
  
const searchDoctorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_DOCTORS_REQUEST:
            return { ...state, loading: true, error: null };
        case SEARCH_DOCTORS_SUCCESS:
            return { ...state, loading: false, doctors: action.payload };
        case SEARCH_DOCTORS_FAILURE:
            return { ...state, loading: false, doctors: [], error: action.error };
        default:
            return state;
        }
    };

export default searchDoctorsReducer;