import { FETCH_STAT_RESULTS_FAILURE, FETCH_STAT_RESULTS_REQUEST, FETCH_STAT_RESULTS_SUCCESS } from "constants/StatResults";

export const fetchStatResultsRequest = (startDate, endDate, agency, department) => ({
    type: FETCH_STAT_RESULTS_REQUEST,
    payload: {
        startDate,
        endDate,
        agency,
        department
    }
});

export const fetchStatResultsSuccess = (payload) => ({
    type: FETCH_STAT_RESULTS_SUCCESS,
    payload: payload,
});

export const fetchStatResultsFailure = (payload) => ({
    type: FETCH_STAT_RESULTS_FAILURE,
    payload: payload,
});
