import { FETCH_ORDERED_ORDERS_FAILURE, FETCH_ORDERED_ORDERS_REQUEST, FETCH_ORDERED_ORDERS_SUCCESS } from "constants/OrderStatus/OrderedOrders"

const initState = {
    loadingOrderedOrders: false,
    orderedOrders: [],
    errorMessage: "",
}

const OrderedOrders = (state = initState, action) => {
    switch (action.type) {
        case FETCH_ORDERED_ORDERS_REQUEST:
            return {
                ...state,
                loadingOrderedOrders: true,
            }

        case FETCH_ORDERED_ORDERS_SUCCESS:
            return {
                ...state,
                loadingOrderedOrders: false,
                orderedOrders: action.payload
            }

        case FETCH_ORDERED_ORDERS_FAILURE:
            return {
                ...state,
                loadingOrderedOrders: false,
                errorMessage: action.payload
            }
        default:
            return state
    }
}

export default OrderedOrders