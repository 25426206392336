import {
     FETCH_SAMPLE_IN_PROCESS_FAILURE,
     FETCH_SAMPLE_IN_PROCESS_REQUEST,
     FETCH_SAMPLE_IN_PROCESS_SUCCESS
} from "constants/SampleInProcess"

export const fetchSampleInProcessRequest = (startDate, endDate, agency, department) => {
     return {
          type: FETCH_SAMPLE_IN_PROCESS_REQUEST,
          payload: {
               startDate,
               endDate,
               agency,
               department,
          },
     }
}
export const fetchSampleInProcessSuccess = (payload) => {
     return {
          type: FETCH_SAMPLE_IN_PROCESS_SUCCESS,
          payload: payload
     }
}
export const fetchSampleInProcessFailure = () => {
     return {
          type: FETCH_SAMPLE_IN_PROCESS_FAILURE,
     }
}