import {
   FETCH_ALL_ORDER_FAILURE,
   FETCH_ALL_ORDER_REQUEST,
   FETCH_ALL_ORDER_SUCCESS,
   FETCH_ORDER_OBX_REQUEST,
   FETCH_ORDER_OBX_SUCCESS,
   FETCH_ORDER_OBX_FAILURE,
   UPDATE_ALL_ORDER_REQUEST,
   UPDATE_ALL_ORDER_FAILURE,
   UPDATE_ALL_ORDER_SUCCESS,
   DELETE_ORDER_ALL_PATIENT_SUCCESS,
   SHOW_ALL_ORDER_OBX_DIALOG,
   SAVE_COMMUNICATED_CONTENT_FAILURE,
   SAVE_COMMUNICATED_CONTENT_REQUEST,
   SAVE_COMMUNICATED_CONTENT_SUCCESS,
   DELETE_ORDER_ALL_PATIENT_FAILURE,
   DELETE_ORDER_ALL_PATIENT_REQUEST,
   UPDATE_PATIENT_DEMOGRAPHY_SUCCESS,
} from "constants/OrderStatus/all-orders"
import { changeFormatedDatetoHumanReadableFormat } from "utils/DateTimeUtil"
import { deleteOrderAllPatient, updateOrder, updatePatientDemographySuccess } from "utils/Order"

const initState = {
   loading: false,
   loadingObx: false,
   orderStatus: false,
   deleteLoading: false,
   list: [],
   loadingCommunicationFeature: false,
   obxList: [],
   updatedOrder: [],
   errorMessage: "",
   showDialog: false
}

const AllOrders = (state = initState, action) => {
   switch (action.type) {
      case FETCH_ALL_ORDER_REQUEST:
         return {
            ...state,
            loading: true,
         }

      case FETCH_ALL_ORDER_SUCCESS:
         return {
            ...state,
            loading: false,
            list: changeFormatedDatetoHumanReadableFormat(action.payload)
         }

      case FETCH_ALL_ORDER_FAILURE:
         return {
            ...state,
            loading: false,
            errorMessage: action.payload
         }


      case SAVE_COMMUNICATED_CONTENT_REQUEST:
         return {
            ...state,
            loadingCommunicationFeature: true,
         }

      case SAVE_COMMUNICATED_CONTENT_SUCCESS:
         return {
            ...state,
            loadingCommunicationFeature: false,
         }

      case SAVE_COMMUNICATED_CONTENT_FAILURE:
         return {
            ...state,
            loadingCommunicationFeature: false,
         }

      case FETCH_ORDER_OBX_REQUEST:
         return {
            ...state,
            loadingObx: true,
         }

      case FETCH_ORDER_OBX_SUCCESS:
         return {
            ...state,
            loadingObx: false,
            obxList: action.payload
         }

      case FETCH_ORDER_OBX_FAILURE:
         return {
            ...state,
            loadingObx: false,
            errorMessage: action.payload
         }

      case UPDATE_ALL_ORDER_REQUEST:
         return {
            ...state,
            loadingObx: true,
            orderStatus: true,
         }
      case UPDATE_ALL_ORDER_SUCCESS:
         return {
            ...state,
            loadingObx: false,
            orderStatus: false,
            showDialog: false,
            list: updateOrder(state, action)
         }
      case UPDATE_PATIENT_DEMOGRAPHY_SUCCESS:
         return {
            ...state,
            list: updatePatientDemographySuccess(state, action)
         }
      case UPDATE_ALL_ORDER_FAILURE:
         return {
            ...state,
            showDialog: false,
            errorMessage: action.payload,
            orderStatus: false
         }

      case DELETE_ORDER_ALL_PATIENT_REQUEST:
         return {
            ...state,
            loading: true,
         }

      case DELETE_ORDER_ALL_PATIENT_SUCCESS:
         return {
            ...state,
            loading: false,
            list: deleteOrderAllPatient(state, action)
         }

      case DELETE_ORDER_ALL_PATIENT_FAILURE:
         return {
            ...state,
            loading: false,
         }

      case SHOW_ALL_ORDER_OBX_DIALOG:
         return {
            ...state,
            showDialog: action.payload
         }

      default:
         return state;

   }
}

export default AllOrders