import { FETCH_REDRAW_SAMPLES_FAILURE, FETCH_REDRAW_SAMPLES_REQUEST, FETCH_REDRAW_SAMPLES_SUCCESS } from "constants/OrderStatus/RedrawSamples"

export const fetchRedrawSamplesRequest = (startDate, endDate, agency) => {
    return {
        type: FETCH_REDRAW_SAMPLES_REQUEST,
        payload: {
            startDate,
            endDate,
            agency
        }
    }
}

export const fetchRedrawSamplesSuccess = (payload) => {
    return {
        type: FETCH_REDRAW_SAMPLES_SUCCESS,
        payload: payload
    }
}

export const fetchRedrawSamplesFailure = (payload) => {
    return {
        type: FETCH_REDRAW_SAMPLES_FAILURE,
        payload: payload
    }
}