import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar, Modal, Input, Row, Col, Form, InputNumber, Select } from "antd";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  FieldNumberOutlined,
  MedicineBoxOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { fetchClientCompaniesRequest, updateUserRequest } from "redux/actions";
import { set } from "lodash";
import { doesThisUserIsAsclepiusUser, doesThisUserIsDoctor } from "utils/CurrentUserUtil";
const { Option } = Select;


const NavProfile = ({ signOut, currentUser, language, clientCompanies, fetchClientCompanies, updateUserInfo, img_url }) => {

  const menuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined,
      path: "/"
    },

    {
      title: "Account Setting",
      icon: SettingOutlined,
      path: "/"
    },
    {
      title: "Billing",
      icon: ShopOutlined,
      path: "/"
    },
    {
      title: "Help Center",
      icon: QuestionCircleOutlined,
      path: "/"
    }
  ]

  const doctorMenuItem = [
    {
      title: "Edit Profile",
      icon: EditOutlined,
      path: "/profile/update"
    }
  ]

  /*   const rules = {NavProfile
    name: [
      {
        required: true,
        message: "Please input your full name",
      },
    ],
    email: [
      {
        required: true,
        message: "Please input your email address",
      },
      {
        type: "email",
        message: "Please enter a validate email!",
      },
    ],
    phone_number: [
      {
        required: true,
        message: "Please input your phone number",
      },
    ],
    username: [
      {
        required: true,
        message: "Please input your username",
      },
    ],
    password: [
      {
        required: true,
        message: "Please input your password",
      },
    ],
    confirm: [
      {
        required: true,
        message: "Please confirm your password!",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject("Passwords do not match!");
        },
      }),
    ]
  }; */

  const parts = img_url.split('/');
  const ImageField = parts[parts.length - 1];

  const profileImg = ImageField ? img_url : `https://ui-avatars.com/api/?name=${currentUser?.name}`;
  const [visible, setVisible] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  //filtering the agency and make an array of an agency ( client companies)
  const tempAgencyArray = clientCompanies.map(singleRow => singleRow.name);
  const ArrayBasedClientCompanies = [...new set(tempAgencyArray)];
  const [age, setAge] = useState(null);
  const [practice_level, setPracticeLevel] = useState(null);
  const [medical_license_number, setMedicalLicenseNumber] = useState(null);
  const [facility, setFacility] = useState(null);
  const [sex, setSex] = useState(null)
  const [medSchoolName, setMedSchoolName] = useState(null)
  const history = useHistory();

  useEffect(() => {
   if(!doesThisUserIsAsclepiusUser()){
    fetchClientCompanies()
   } 
  }, [fetchClientCompanies])

  const handleUpdateUserInfo = (currentUser) => {
    const updatedUser = {
      ...currentUser,
      age: age,
      practice_level: practice_level,
      medical_license_number: medical_license_number,
      facility: facility,
      sex: sex,
      medical_school_attended_name: medSchoolName,
    }
    updateUserInfo(updatedUser)
    currentUser = updatedUser
    setEditProfileModal(false)
  }

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{currentUser?.name}</h4>
            <span className="text-muted">{currentUser?.roles[0]}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        {doesThisUserIsDoctor() ? (
          <Menu>
            {doctorMenuItem.map((el, i) => {
              return (
                <Menu.Item key={i} onClick={() => showModal(el.title)}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Menu.Item>
              );
            })}
            <Menu.Item key={menuItem.length + 1} onClick={e => showModal(language.signOut)}>
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">{language.signout}</span>
              </span>
            </Menu.Item>
          </Menu>
        ) : (
          <Menu>
            {menuItem.map((el, i) => {
              return (
                <Menu.Item key={i} onClick={() => showModal(el.title)}>
                  {/* <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a> */}
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Menu.Item>
              );
            })}
            <Menu.Item key={menuItem.length + 1} onClick={e => showModal(language.signOut)}>
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">{language.signout}</span>
              </span>
            </Menu.Item>
          </Menu>
        )}
      </div>
    </div>
  );

  function showModal(title) {
    if (title === 'Edit Profile' && doesThisUserIsDoctor()) {
      //setEditProfileModal(true);
      history.push('/doctor/profile/update')
    } else {
      setVisible(true);
    }
  }

  function hideModal() {
    setVisible(false);
    setEditProfileModal(false);
  }
  function confirmLogout() {
    setVisible(false);
    signOut();
  }

  return (
    <>
    <Modal title="Are you sure ?" visible={visible} onCancel={hideModal} onOk={confirmLogout}>
          <h5>Log out</h5>
    </Modal>
    {/* <Modal width={1300} title="Edit your profile" visible={editProfileModal} onCancel={hideModal} onOk={() => handleUpdateUserInfo(currentUser)}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={20} lg={11}>
                  <Form.Item
                    name="age"
                    label="Age"
                    rules={[{ required: true, message: 'please enter your age' }]}
                    hasFeedback
                  >
                    <InputNumber min={20} max={100} style={{width: '100%'}} onChange={(value) => setAge(value)} />
                  </Form.Item>
                </Col>
                
                <Col xs={24} sm={24} md={11}>
                  <Form.Item
                    name="sex"
                    label="Sex"
                    rules={[{ required: true, message: 'please enter your sex' }]}
                  >
                    <Select
                      showSearch
                      className=".ant-select-selection"
                      allowClear
                      onChange={(value)=> setSex(value) }
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={11}>
            <Form.Item
              name="practice_level"
              label="Practice Level"
              rules={[{ required: true, message: language.field_required }]}
            >
              <Select showSearch className="ant-select-selection" allowClear onChange={(value) => setPracticeLevel(value)}>
                <Option value="Intern">Intern</Option>
                <Option value="GP">General Practitioner</Option>
                <Option value="specialist">Specialist</Option>
                <Option value="sub-specialist">Sub-specialist</Option>
              </Select>
            </Form.Item>
          </Col>


          <Col xs={24} sm={24} md={11}>
            <Form.Item
              name="facility"
              label="Facility"
              rules={[{ required: true, message: language.field_required }]}
            >
              <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder="Input place of work"
                onChange={(value) => setFacility(value.map((facility) => ({ facility_name: facility })))}
              >
                {ArrayBasedClientCompanies.map((agency) => (
                  <Option key={agency} value={agency}>{agency}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={11}>
            <Form.Item
              name="medical_license_number"
              label="Medical License Number"
              rules={[{ required: true, message: language.field_required }]}
            >
              <Input value='' placeholder="Input your medical license number" prefix={<FieldNumberOutlined className="text-primary" />} onChange={(e) => setMedicalLicenseNumber(e.target.value)} />
            </Form.Item>
          </Col>


                <Col xs={24} sm={24} md={11}>
                  <Form.Item
                    name="medical_school_attended_name"
                    label="Medical School Attended Name"
                    rules={[{ required: true, message: language.field_required }]}
                  >
                    <Input
                      value=''
                      placeholder="Input name of your medical school"
                      prefix={<MedicineBoxOutlined className="text-primary" />}
                      onChange={(e) => setMedSchoolName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
    </Modal>   */}
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
    </>
  );
}

const mapStateToProps = (state) => ({
  //currentUser: state.auth.currentUser,
  clientCompanies: state.clientCompany.list,
  img_url: state.theme.currentUserImagePath,
})

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
  fetchClientCompanies: () => dispatch(fetchClientCompaniesRequest()),
  updateUserInfo: (updatedUser) => dispatch(updateUserRequest(updatedUser)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
