import { ADD_DOCTORS_BY_CLIENT_COMPANY_FAILURE, ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST, ADD_DOCTORS_BY_CLIENT_COMPANY_SUCCESS } from "constants/addDoctorsByClinetCompany";

export const addDoctorsByClientCompanyRequest = (doctorId, agencyId) => ({
    type: ADD_DOCTORS_BY_CLIENT_COMPANY_REQUEST,
    payload: { doctorId, agencyId },
});
  
export const addDoctorsByClientCompanySuccess = (response) => ({
    type: ADD_DOCTORS_BY_CLIENT_COMPANY_SUCCESS,
    payload: response,
});
  
export const addDoctorsByClientCompanyFailure = (error) => ({
    type: ADD_DOCTORS_BY_CLIENT_COMPANY_FAILURE,
    payload: error,
});